import { ChangeDetectionStrategy, Component, Input, type OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ELanguages } from '@common/enums';
import { take } from 'rxjs/operators';
import {
    AnalyticsSettingsFormGroupType,
    EPixelTypes,
    GoogleSettingsFormGroupType,
    PixelSettingsFormGroupType,
} from '@app/features/common/components/analytics-settings';
import { SettingsFacade } from '@app/store/site/settings/settings.facade';

@Component({
    selector: 'app-analytics-settings',
    templateUrl: './analytics-settings.component.html',
    styleUrls: ['./analytics-settings.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsSettingsComponent implements OnInit {
    @Input() public itemForm: AnalyticsSettingsFormGroupType;
    @Input() public pixelAnalyticType: EPixelTypes;
    @Input() public isPaymentForm: boolean;

    siteSettings$ = this.settingsFacade.siteSettings$;
    public toggleOptions = false;
    protected readonly pixelTypes = EPixelTypes;

    constructor(protected readonly settingsFacade: SettingsFacade, private translate: TranslateService) {}

    ngOnInit() {
        this.siteSettings$.pipe(take(1)).subscribe((siteSettings) => {
            if (!siteSettings.google_analytics) this.toggleGoogleAnalyticControl(false);
            if (!siteSettings.facebook_pixel) this.togglePixelAnalyticControl(false);
        });
    }

    public toggleGoogleAnalyticControl(event: boolean) {
        this.googleAnalyticControl.patchValue(event);
    }

    public togglePixelAnalyticControl(event: boolean) {
        this.pixelAnalyticControl.patchValue(event);
        if (event) {
            this.pixelTypeControl.patchValue(this.pixelAnalyticType);
        }
    }

    public get googleAnalyticControl() {
        return this.itemForm.get('googleAnalytic');
    }

    public get pixelAnalyticControl() {
        return this.itemForm.get('pixelAnalytic');
    }

    public get pixelTypeControl() {
        return this.pixelSettingsGroup.get('type');
    }

    public get googleGoogleSettingsGroup(): GoogleSettingsFormGroupType {
        return this.itemForm.get('googleSettings') as GoogleSettingsFormGroupType;
    }

    public get pixelSettingsGroup(): PixelSettingsFormGroupType {
        return this.itemForm.get('pixelSettings') as PixelSettingsFormGroupType;
    }

    public get helpGALink(): string {
        return `https://sendpulse.com/${this.lang}knowledge-base/landing-page/statistics-and-analytics/connect-google-analytics`;
    }

    public get helpPixelLink(): string {
        return `https://sendpulse.com/${this.lang}knowledge-base/landing-page/statistics-and-analytics/connect-facebook-pixel`;
    }

    private get lang(): string {
        const languages = [ELanguages.RU, ELanguages.UA, ELanguages.PT_BR];

        return languages.includes(this.translate.currentLang as ELanguages) ? `${this.translate.currentLang}/` : '';
    }
}
