export const TRACKING_LINK_USERS_WHITELIST = [
    8248527,
    8096579,
    8557662,
    8627885,
    8654083,
    6991802,
    8100057,
    8729240,
    8320486,
    8408480,
    8297321,
    7824090,
    8097386,
    8807412,
    8814645,
];
