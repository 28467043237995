import { Injectable, Inject, inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SiteSettings } from '@common/types/site.type';

@Injectable({
    providedIn: 'root',
})
export class ExternalAnalyticsService {
    private document: Document = inject(DOCUMENT);

    private injectHtml(html: string, target: 'body' | 'head' = 'body'): void {
        const container = this.document.querySelector(target);
        container.insertAdjacentHTML('beforeend', html);
    }

    private injectYandexMetrika(counterId: string): void {
        const html = `
        <script type="text/javascript">
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();
            for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
            ym(${counterId}, "init", {clickmap:true,trackLinks:true,accurateTrackBounce:true,webvisor:true});
        </script>
        `;
        this.injectHtml(html);
    }

    private injectGoogleAnalytics(googleAnalyticsId: string): void {
        const html = `
        <script async src="https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}"></script>
        <script>
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', '${googleAnalyticsId}');
        </script>
        `;
        this.injectHtml(html);
    }

    private injectFacebookPixel(pixelId: string): void {
        const html = `
        <script>
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${pixelId}');
            fbq('track', 'PageView');
        </script>
        `;
        this.injectHtml(html);
    }

    private injectVkPixel(pixelId: string): void {
        const html = `
        <script type="text/javascript">
            !function () {
                var t = document.createElement("script");
                t.type = "text/javascript", t.async = !0, t.src = "https://vk.com/js/api/openapi.js?168", t.onload = function () {
                    VK.Retargeting.Init("${pixelId}"), VK.Retargeting.Hit()
                }, document.head.appendChild(t)
            }();
        </script>
        `;
        this.injectHtml(html);
    }

    private injectFacebookMetaTag(metaTag: string): void {
        this.injectHtml(metaTag, 'head');
    }

    public injectExternalAnalyticsScripts(settings: SiteSettings): void {
        if (settings.yandex_metrika) {
            this.injectYandexMetrika(settings.yandex_metrika);
        }

        if (settings.google_analytics) {
            this.injectGoogleAnalytics(settings.google_analytics);
        }

        if (settings.facebook_pixel) {
            this.injectFacebookPixel(settings.facebook_pixel);
        }

        if (settings.vk_pixel) {
            this.injectVkPixel(settings.vk_pixel);
        }

        if (settings.facebook_meta_tag) {
            this.injectFacebookMetaTag(settings.facebook_meta_tag);
        }
    }
}
