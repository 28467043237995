import { type FormControl, type FormGroup } from '@angular/forms';

export type AnalyticsSettings = {
    googleAnalytic: boolean;
    pixelAnalytic: boolean;
    googleSettings?: GoogleSettings;
    pixelSettings?: PixelSettings;
};

export type GoogleSettings = {
    category: string;
    action: string;
    label: string;
    currency?: string;
    value?: number;
    leadSource?: string;
};

export type PixelSettings = {
    type: EPixelTypes;
    currency?: string;
    value?: string;
};

export enum EPixelTypes {
    addPaymentInfo = 'AddPaymentInfo',
    addToCart = 'AddToCart',
    addToWishlist = 'AddToWishlist',
    completeRegistration = 'CompleteRegistration',
    contact = 'Contact',
    customizeProduct = 'CustomizeProduct',
    donate = 'Donate',
    findLocation = 'FindLocation',
    initiateCheckout = 'InitiateCheckout',
    lead = 'Lead',
    purchase = 'Purchase',
    schedule = 'Schedule',
    search = 'Search',
    startTrial = 'StartTrial',
    submitApplication = 'SubmitApplication',
    subscribe = 'Subscribe',
    viewContent = 'ViewContent',
}

export type AnalyticsSettingsFormType = {
    googleAnalytic: FormControl<AnalyticsSettings['googleAnalytic']>;
    pixelAnalytic: FormControl<AnalyticsSettings['pixelAnalytic']>;
    googleSettings: GoogleSettingsFormGroupType;
    pixelSettings: PixelSettingsFormGroupType;
};

export type GoogleSettingsFormType = {
    category: FormControl<GoogleSettings['category']>;
    action: FormControl<GoogleSettings['action']>;
    label: FormControl<GoogleSettings['label']>;
    currency: FormControl<GoogleSettings['currency']>;
    value: FormControl<GoogleSettings['value']>;
    leadSource: FormControl<GoogleSettings['leadSource']>;
};

export type PixelSettingsFormType = {
    type: FormControl<PixelSettings['type']>;
};

export type AnalyticsSettingsFormGroupType = FormGroup<AnalyticsSettingsFormType>;

export type GoogleSettingsFormGroupType = FormGroup<GoogleSettingsFormType>;

export type PixelSettingsFormGroupType = FormGroup<PixelSettingsFormType>;

export type PaymentGoogleSettings = {
    currency: string,
    value: number,
    productName: string,
}
