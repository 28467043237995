import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    type OnChanges,
    type OnInit,
    Output,
    type SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { MakeProvider, SpInputAbstract } from '@common/components/sp-form-controls/abstract/sp-input-abstract';

@Component({
    selector: 'sp-input-num',
    templateUrl: './sp-input-num.component.html',
    styleUrls: ['./sp-input-num.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MakeProvider(SpInputNumComponent)],
})
export class SpInputNumComponent extends SpInputAbstract<number> implements OnInit, OnChanges {
    @Input() public suffix!: string;
    @Input() public label!: string;
    @Input() public control!: UntypedFormControl;
    @Input() public min!: number;
    @Input() public max!: number;
    @Input() public step!: number;
    @Input() public suffixText!: string;
    @Input() public prefixText!: string;
    @Input() public prefixImgUrl!: string;
    @Input() public suffixList: string[];
    @Input() public suffixValue: string;
    @Input() public onlyIntegers: boolean = false;

    @Output() public blurEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() public suffixChangeEvent: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('inputEl') public inputEl!: ElementRef;

    private _focused: boolean = false;

    public ngOnInit(): void {
        super.init();
        super.subscribeOnFormControlValueChange();
    }

    protected getValidValue(value: number) {
        const isHaveRange: boolean = !!(this.min || this.max);

        if (isHaveRange) {
            if (typeof this.min === 'number') {
                this.control.setValidators(Validators.min(this.min));
                value = this.checkMinRange(value);
            }
            if (typeof this.max === 'number') {
                this.control.setValidators(Validators.max(this.max));
                value = this.checkMaxRange(value);
            }
        }
        if (this.onlyIntegers) {
            value = Math.round(value);
        }

        return value;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const change = changes.value;
        if (change && !change.firstChange && !this._focused && change.currentValue !== change.previousValue) {
            this.control.setValue(this.getValidValue(change.currentValue), { emitEvent: false });
        }
    }

    private checkMinRange(value: number): number {
        return value >= this.min && value !== null ? value : this.min;
    }

    private checkMaxRange(value: number): number {
        return this.max >= value ? value : this.max;
    }

    public focusEventHandler(): void {
        this._focused = true;
    }

    public blurEventHandler(): void {
        this.control.setValue(this.getValidValue(this.value), { emitEvent: false });

        this.blurEvent.emit(this.control.value);
        this._focused = false;
    }

    public keypressHandler(event: KeyboardEvent): void {
        if (this.onlyIntegers) {
            const key = event.key;
            if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete') {
                event.preventDefault();
            }
        }
    }
}
