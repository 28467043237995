import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, type FormControl } from '@angular/forms';
import { GoogleSettingsFormGroupType } from '@app/features/common/components/analytics-settings';

@Component({
    selector: 'app-google-settings',
    templateUrl: './google-settings.component.html',
    styleUrls: ['./google-settings.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleSettingsComponent {
    @Input() public googleSettings: GoogleSettingsFormGroupType;

    constructor(private readonly fb: FormBuilder) {}

    public get categoryControl(): FormControl<string> {
        return this.googleSettings.get('category') as FormControl<string>;
    }

    public get actionControl(): FormControl<string> {
        return this.googleSettings.get('action') as FormControl<string>;
    }

    public get labelControl(): FormControl<string> {
        return this.googleSettings.get('label') as FormControl<string>;
    }

    public get currencyControl(): FormControl<string> {
        return this.googleSettings.get('currency') as FormControl<string>;
    }

    public get valueControl(): FormControl<number> {
        return this.googleSettings.get('value') as FormControl<number>;
    }

    public get leadSourceControl(): FormControl<string> {
        return this.googleSettings.get('leadSource') as FormControl<string>;
    }

    public setValueControlValidator() {
        if (this.currencyControl.value) {
            if (!this.valueControl.value) {
                this.valueControl.setErrors({'incorrect': true});
            }
        } else if (!this.currencyControl.value && this.valueControl.value) {
            this.currencyControl.setErrors({'incorrect': true});
        } else {
            this.valueControl.setErrors(null);
            this.currencyControl.setErrors(null);
        }
    }

    public setCurrencyControlValidator() {
        if (this.valueControl.value) {
            if (!this.currencyControl.value) {
                this.currencyControl.setErrors({'incorrect': true});
            }
        } else if (!this.valueControl.value && this.currencyControl.value) {
            this.valueControl.setErrors({'incorrect': true});
        } else {
            this.valueControl.setErrors(null);
            this.currencyControl.setErrors(null);
        }
    }
}
