import { Inject, Injectable, type Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { PlatformService } from '@common/services/platform.service';

import { getValuesAsString } from '@common/helpers';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private readonly renderer: Renderer2;
    private readonly styleElement: HTMLStyleElement;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly platformService: PlatformService,
        private readonly rendererFactory2: RendererFactory2,
    ) {
        if (this.platformService.isPlatformServer()) {
            this.renderer = this.rendererFactory2.createRenderer(null, null);
            this.styleElement = this.renderer.createElement('style');
            this.renderer.appendChild(this.document.querySelector('head'), this.styleElement);
        }
    }

    public setThemeValues(values: Record<string, string>): void {
        const rootValue = getValuesAsString(values);
        this.styleElement.innerHTML = `:root { ${rootValue} }`;
    }
}
