import { Injectable, TransferState, inject, makeStateKey, type StateKey } from '@angular/core';
import { EContentSources } from '@common/enums';

import { PlatformService } from '@common/services/platform.service';
import { IS_PREVIEW } from '@common/tokens';
import type { ValueOf } from '@common/types';
import { PageSettings } from '@common/types/page-settings.type';

import { environment } from '@web-builder-env/environment';
import type { RequestDTO } from '@web-builder-server/types';

type TransferStateObject = RequestDTO & {
    page: {
        id: number;
        contentSourceId: number;
        settings: Partial<PageSettings>;
    };
    courseId: number;
    translations: Record<string, string>;
    templateId: string;
    siteUrl: string;
};

@Injectable({
    providedIn: 'root',
})
export class TransferStateService {
    private readonly transferState: TransferState = inject(TransferState);
    private readonly platformService: PlatformService = inject(PlatformService);
    private readonly isPreviewToken: boolean = inject(IS_PREVIEW, { optional: true });
    private readonly isPreviewStateKey: StateKey<boolean> = makeStateKey<boolean>('isPreview');
    private readonly transferStateKeys: Record<keyof TransferStateObject, StateKey<TransferStateObject[keyof TransferStateObject]>> = {
        structure: makeStateKey<TransferStateObject['structure']>('structure'),
        themeSettings: makeStateKey<TransferStateObject['themeSettings']>('themeSettings'),
        lang: makeStateKey<TransferStateObject['lang']>('lang'),
        pageId: makeStateKey<TransferStateObject['pageId']>('pageId'),
        siteId: makeStateKey<TransferStateObject['siteId']>('siteId'),
        googleMapsApiKey: makeStateKey<TransferStateObject['googleMapsApiKey']>('googleMapsApiKey'),
        domainUrl: makeStateKey<TransferStateObject['domainUrl']>('domainUrl'),
        domainId: makeStateKey<TransferStateObject['domainId']>('domainId'),
        userId: makeStateKey<TransferStateObject['userId']>('userId'),
        showPoweredBy: makeStateKey<TransferStateObject['showPoweredBy']>('showPoweredBy'),
        eduTariff: makeStateKey<TransferStateObject['eduTariff']>('eduTariff'),
        pageUrl: makeStateKey<TransferStateObject['pageUrl']>('pageUrl'),
        page: makeStateKey<TransferStateObject['page']>('page'),
        site: makeStateKey<TransferStateObject['site']>('site'),
        courseId: makeStateKey<TransferStateObject['courseId']>('courseId'),
        translations: makeStateKey<TransferStateObject['translations']>('translations'),
        templateId: makeStateKey<TransferStateObject['templateId']>('templateId'),
        siteUrl: makeStateKey<TransferStateObject['siteUrl']>('siteUrl'),
    };

    constructor() {
        if (this.platformService.isPlatformServer()) {
            this.transferState.set(this.isPreviewStateKey, this.isPreviewToken);
        }
    }

    public set<K extends keyof TransferStateObject, V extends TransferStateObject[K]>(key: K, value: V): V | undefined {
        if (this.platformService.isPlatformServer()) {
            try {
                this.transferState.set(this.transferStateKeys[key], value);
                console.log(`[SET] TransferStateService: key ${key} set to ${value} [${typeof value}]`);
                return value;
            } catch (error) {
                console.log(`[SET ERROR] TransferStateService: key ${key} not set`);
            }
        }
    }

    public get<T extends keyof TransferStateObject>(key: T): any {
        if (this.transferState.hasKey(this.transferStateKeys[key])) {
            return this.transferState.get<TransferStateObject[T]>(this.transferStateKeys[key] as StateKey<TransferStateObject[T]>, null);
        }
        console.log(`[GET] TransferStateService: key ${key} not found`);
    }

    public get resourceUrl(): string {
        return this.isPreview || environment.type === 'dev' ? environment.memberAreaUrl : environment.proxyUrl;
    }

    public get isPreview(): boolean {
        if (this.platformService.isPlatformServer()) {
            return this.isPreviewToken;
        }

        return this.transferState.get(this.isPreviewStateKey, false);
    }
}
