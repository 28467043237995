import { inject, Injectable } from '@angular/core';
import { BaseHttpService } from './base.http.service';
import { shareReplay, type Observable } from 'rxjs';
import { X_REAL_IP } from '@common/tokens';

type GeoIpResponse = {
    organization: string;
    region: string;
    asn: number;
    ip: string;
    timezone: string;
    longitude: number;
    postal_code: string;
    city: string;
    continent_code: string;
    country: string;
    region_code: string;
    country_code: string;
    latitude: number;
};
@Injectable({
    providedIn: 'root',
})
export class CountryISOService extends BaseHttpService {
    private readonly xRealIp: string = inject(X_REAL_IP, { optional: true });

    public getCountryISO(): Observable<any> {
        return this.http.get<any>(`https://geoip.sendpulse.com${this.xRealIp ? '/' + this.xRealIp : ''}`).pipe(shareReplay(1));
    }
}
