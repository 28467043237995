import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { RESOURCE_URL } from '@sites/analytics/src/app/shared/tokens';

@Injectable({ providedIn: 'root' })
export class UseAnalytics {
    private readonly http: HttpClient = inject(HttpClient);
    private readonly resourceUrl = inject(RESOURCE_URL);

    public trackPageVisit({ pageId, deviceType, ip, referrer }: { pageId: number; deviceType?: 1 | 2; ip?: string; referrer: string }) {
        const headers: { [key: string]: string } = {
            'X-Real-Referrer': referrer,
        };

        if (ip) {
            headers['X-Real-IP'] = ip;
        }

        if (deviceType) {
            headers['X-Device-Type'] = deviceType.toString();
        }

        return this.http
            .post(
                `${this.resourceUrl}/api/landings-service/public/visit`,
                {
                    pageId,
                },
                {
                    headers,
                },
            )
            .subscribe();
    }
}
