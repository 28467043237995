import { HttpClient, HttpContext } from '@angular/common/http';
import { inject } from '@angular/core';
import { type CanMatchFn, type Route, type UrlSegment } from '@angular/router';
import { catchError, map, of, shareReplay, tap } from 'rxjs';
import { type Request as ExpressRequest } from 'express';

import { EContentSources } from '@common/enums';
import { WebThemeFormatter, WebsiteThemeStrategy, type ThemeSettingsState } from '@common/libs/themes';
import { PlatformService } from '@common/services/platform.service';
import { ServerStylesService } from '@common/services/server-styles.service';
import * as tokens from '@common/tokens';
import { SimpleThemeFormatter } from '@common/libs/themes/src/strategies/theme-formatter/simple-theme-formatter';

import { ThemeService } from '@web-builder/mls-core/services/theme.service';

import { formatStructure } from '@web-builder/core/helpers/format-structure';
import { getMapsApiKey } from '@web-builder/core/helpers/maps-api-key';
import { SKIP_AUTH } from '@web-builder/core/interceptors/jwt.interceptor';
import { LinksService } from '@web-builder/core/services/links.service';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { type BlockStructureEntity } from '@web/types';
import { TranslateService } from '@ngx-translate/core';
import { buildSchema } from '@web-builder-server/validation-schemas/build-request.schema';
import { type PreviewRequest } from '@web-builder-server/types';
import { createBuildRequestDto, createPreviewRequestDto } from '@web-builder-server/dto';
import { Meta, Title } from '@angular/platform-browser';
import { ExternalAnalyticsService } from '@web-builder/core/services/external-analytics.service';

const context = new HttpContext().set(SKIP_AUTH, true);

function getWebsiteData(url: string) {
    const http: HttpClient = inject(HttpClient);
    const apiLandingsServiceUrl: string = inject(tokens.API_LANDINGS_SERVICE_URL);
    const isPreview: boolean = inject(tokens.IS_PREVIEW, { optional: true });

    return http.get(`http://${apiLandingsServiceUrl}/soa/build?url=${url}`, { context }).pipe(
        map((data: any) => data.data),
        // map((data: any) => (isPreview ? createPreviewRequestDto(data) : createBuildRequestDto(data))),
        // tap(console.log),
        shareReplay(1),
        catchError((error, caught) => {
            console.log(error);
            return of({ data: null });
        }),
    );
}

const siteDataObservable = () => {
    const transferStateService: TransferStateService = inject(TransferStateService);
    const request: ExpressRequest<{}, {}, PreviewRequest> = inject(tokens.REQUEST, { optional: true });
    const isPreview: boolean = inject(tokens.IS_PREVIEW, { optional: true });

    return isPreview ? of(request.body) : getWebsiteData(transferStateService.get('siteUrl'));
};

const createCanMatchFn = (contentSourcesId: EContentSources[]): CanMatchFn => {
    return (route: Route, segments: UrlSegment[]) => {
        const platformService: PlatformService = inject(PlatformService);
        const transferStateService: TransferStateService = inject(TransferStateService);

        if (platformService.isPlatformServer()) {
            const url = segments.join('/');
            if (!url) return false;

            transferStateService.set('siteUrl', url);

            return siteDataObservable().pipe(
                map((data: any) => {
                    if (!data) return false;

                    const { page } = data;
                    transferStateService.set('page', page);
                    return contentSourcesId.includes(page.contentSourceId);
                }),
            );
        }

        // platform browser
        return contentSourcesId.includes(transferStateService.get('page')?.contentSourceId);
    };
};

export const canMatchWeb: CanMatchFn = createCanMatchFn([EContentSources.Website, EContentSources.WebEducation]);
export const canMatchMLP: CanMatchFn = createCanMatchFn([
    EContentSources.MiniLanding,
    EContentSources.Education,
    EContentSources.ChatBot,
    EContentSources.PopUp,
]);

export const initializeWebsites = () => {
    const platformService: PlatformService = inject(PlatformService);
    const transferStateService: TransferStateService = inject(TransferStateService);
    const translateService: TranslateService = inject(TranslateService);
    const metaService: Meta = inject(Meta);
    const title: Title = inject(Title);
    const externalANalyticsService: ExternalAnalyticsService = inject(ExternalAnalyticsService);

    if (platformService.isPlatformServer()) {
        const stylesService: ServerStylesService = inject(ServerStylesService);
        const linkService: LinksService = inject(LinksService);
        const isPreview: boolean = inject(tokens.IS_PREVIEW, { optional: true });

        siteDataObservable()
            .pipe(
                map((data: any) => {
                    if (!data) return false;

                    const { error, value } = buildSchema.validate(data, {
                        abortEarly: false,
                        allowUnknown: true,
                        // stripUnknown: true,
                    });

                    // console.log('[VALIDATION ERROR]', error);
                    // console.log('[VALIDATION VALUE]', value);

                    let newData: any = {};
                    if (isPreview) {
                        const { structure, themeSettings, pageId, siteId, showPoweredBy } = data;

                        newData = {
                            structure,
                            themeSettings,
                            showPoweredBy,
                            siteId,
                            pageId,
                        };
                    } else {
                        const { structure, themeSettings, sharedBlocks, page, site, lang, domain, user, showPoweredBy, edu } = data;

                        const parsedStructure = JSON.parse(structure) as BlockStructureEntity[];
                        newData = {
                            structure: formatStructure(
                                parsedStructure,
                                sharedBlocks,
                                page.id,
                                Number(site?.settings?.not_found_page_id) === page.id,
                            ),
                            themeSettings: JSON.parse(themeSettings) as ThemeSettingsState,
                            lang,
                            pageId: page.id,
                            siteId: site.id,
                            googleMapsApiKey: getMapsApiKey(parsedStructure),
                            domainUrl: domain?.url,
                            domainId: domain?.id,
                            userId: user?.id,
                            eduTariff: edu?.tariff?.abbr,
                            showPoweredBy,
                            pageUrl: page?.settings?.url,
                            site,
                            page,
                        };

                        externalANalyticsService.injectExternalAnalyticsScripts(site.settings);
                        import(`../../../assets/translations/${lang}.json`).then(({ default: translations }) => {
                            const shouldMerge = false;
                            transferStateService.set('translations', translations);
                            translateService.setTranslation(lang, translations, shouldMerge);
                            translateService.use(lang);
                        });
                    }

                    console.log('[NEW DATA]', newData);
                    Object.keys(newData).forEach((key) => {
                        transferStateService.set(key as any, newData[key]);
                    });

                    const themeStyles = {
                        '@global': {
                            ':root': getFormattedThemeSettings(newData.themeSettings),
                        },
                    };

                    stylesService.getStyles('theme', themeStyles);
                    linkService.setFontsLinks(newData.themeSettings.fonts);

                    if (newData?.page?.settings?.can_be_indexed) {
                        metaService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
                    } else {
                        metaService.removeTag("name='robots'");
                    }

                    if (newData?.page?.settings?.description) {
                        metaService.updateTag({ name: 'description', content: newData.page.settings.description });
                    } else {
                        metaService.removeTag("name='description'");
                    }

                    if (newData?.page?.settings?.seo_name) {
                        title.setTitle(newData.page.settings.seo_name);
                    }
                }),
            )
            .subscribe();
    }

    const translations = transferStateService.get('translations');
    const lang = transferStateService.get('lang');
    const shouldMerge = false;
    translateService.setTranslation(lang, translations, shouldMerge);
    translateService.use(lang);
};

export const initializeMLP = () => {
    const platformService: PlatformService = inject(PlatformService);
    const transferStateService: TransferStateService = inject(TransferStateService);
    const translateService: TranslateService = inject(TranslateService);
    const metaService: Meta = inject(Meta);
    const title: Title = inject(Title);
    const externalAnalyticsService: ExternalAnalyticsService = inject(ExternalAnalyticsService);

    if (platformService.isPlatformServer()) {
        const linkService: LinksService = inject(LinksService);
        const isPreview: boolean = inject(tokens.IS_PREVIEW, { optional: true });
        const themeService: ThemeService = inject(ThemeService);
        const simpleThemeFormatter = new SimpleThemeFormatter();

        siteDataObservable()
            .pipe(
                map((data: any) => {
                    if (!data) return false;

                    const { error, value } = buildSchema.validate(data, {
                        abortEarly: false,
                        allowUnknown: true,
                        // stripUnknown: true,
                    });

                    // console.log('[VALIDATION ERROR]', error);
                    // console.log('[VALIDATION VALUE]', value);

                    let newData: any = {};
                    if (isPreview) {
                        const { structure, themeSettings, pageId, siteId, showPoweredBy } = data;

                        newData = {
                            structure,
                            themeSettings,
                            showPoweredBy,
                            siteId,
                            pageId,
                        };
                    } else {
                        const { structure, themeSettings, page, site, lang, domain, user, showPoweredBy, edu, templateId } = data;

                        newData = {
                            structure: JSON.parse(structure),
                            themeSettings: JSON.parse(themeSettings) as ThemeSettingsState,
                            lang,
                            showPoweredBy,
                            page,
                            pageId: page.id,
                            siteId: site.id,
                            userId: user?.id,
                            domainUrl: domain?.url,
                            domainId: domain?.id,
                            templateId,
                            courseId: edu?.course?.id,
                        };

                        externalAnalyticsService.injectExternalAnalyticsScripts(site.settings);
                        import(`../../../../../mini-landing-site/src/assets/translations/${lang}.json`).then(
                            ({ default: translations }) => {
                                const shouldMerge = false;
                                transferStateService.set('translations', translations);
                                translateService.setTranslation(lang, translations, shouldMerge);
                                translateService.use(lang);
                            },
                        );
                    }

                    // console.log('[NEW DATA]', newData);
                    Object.keys(newData).forEach((key) => {
                        transferStateService.set(key as any, newData[key]);
                    });

                    if (newData.page.settings.can_be_indexed) {
                        metaService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
                    } else {
                        metaService.removeTag("name='robots'");
                    }

                    if (newData.page.settings.description) {
                        metaService.updateTag({ name: 'description', content: newData.page.settings.description });
                    } else {
                        metaService.removeTag("name='description'");
                    }

                    if (newData.page.settings.seo_name) {
                        title.setTitle(newData.page.settings.seo_name);
                    }

                    themeService.setThemeValues(simpleThemeFormatter.formatTheme(newData.themeSettings));
                    linkService.setFontsLinks(newData.themeSettings.fonts as any);
                }),
            )
            .subscribe();
    }

    const translations = transferStateService.get('translations');
    const lang = transferStateService.get('lang');
    const shouldMerge = false;
    translateService.setTranslation(lang, translations, shouldMerge);
    translateService.use(lang);
};

function getFormattedThemeSettings(themeSettings: ThemeSettingsState): Record<string, string> {
    const themeFormatter: WebThemeFormatter = new WebThemeFormatter();
    const websiteThemeStrategy: WebsiteThemeStrategy = new WebsiteThemeStrategy();

    try {
        return themeFormatter.formatTheme(themeSettings);
    } catch (error) {
        console.error('Error while formatting theme settings', error);
        const colors = websiteThemeStrategy.colorPresets;
        const buttons = websiteThemeStrategy.buttonPresets;
        const fonts = websiteThemeStrategy.fontPresets;

        return themeFormatter.formatThemeButtons({
            colors: {
                selected: colors[0]?.values!,
                selectedIndex: colors[0]?.index!,
            },
            buttons: {
                selected: buttons[0]?.values!,
                selectedIndex: buttons[0]?.index!,
            },
            fonts: {
                selected: fonts[0]?.values!,
                selectedIndex: fonts[0]?.index!,
            },
        });
    }
}
