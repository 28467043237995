import { type JssStyle } from 'jss';

import { simpleColorCss } from '@libs/themes/src/variants/simple/utils/variables';

import { type IElement } from '@app/features/common/interfaces/IElement';
import { h1Styles, h2Styles, h3Styles, h4Styles, pStyles } from '@app/features/common/styles/text.styles';
import { EHorizontalPosition } from '@common/enums';

const commonTextStyles = {
    '& h1': h1Styles,
    '& h2': h2Styles,
    '& h3': h3Styles,
    '& h4': h4Styles,
    '& p': pStyles,
    '& ul, ol': {
        '& li': pStyles,
    },
};

export function getStyles(element: IElement): JssStyle {
    const elementData = element.data;
    return {
        element: {
            '& .close-acc-item': {
                '& .body': {
                    height: '0px',
                    transition: 'height 3s ease-out',
                    transform: 'scaleY(0)',
                    display: 'block',
                },
            },
            '& .open-acc-item': {
                '& .body': {
                    overflow: 'hidden',
                },
            },
        },
        wrapAccordionItem: {
            borderBottom: elementData.useSeparator ? `1px solid ${simpleColorCss(elementData.separatorColor)}` : 'none',
        },
        wrapHeader: {
            display: 'flex',
            flexDirection: elementData.iconPosition === EHorizontalPosition.R ? 'row' : 'row-reverse',
            justifyContent: 'space-between',
            cursor: 'pointer',
            padding: '5px 0',
        },
        header: {
            flex: 1,
            ...commonTextStyles,
        },
        body: {
            ...commonTextStyles,
        },
        wrapIcon: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: elementData.iconPosition === EHorizontalPosition.R ? '15px' : '0',
            marginRight: elementData.iconPosition !== EHorizontalPosition.R ? '15px' : '0',
            '& .icon': {
                display: 'flex',
                alignItems: 'center',
                color: simpleColorCss(elementData.iconColor),
                '& svg': {
                    height: '15px',
                    width: '15px',
                    '& path': {
                        fill: simpleColorCss(elementData.iconColor),
                    },
                    '& path.stroke': {
                        fill: 'none',
                        stroke: simpleColorCss(elementData.iconColor),
                    },
                },
            },
        },
    };
}
