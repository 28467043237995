import type { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
    type CartFeatureState,
    selectCartItems,
    selectCartTotalPrice,
    selectCartNumberOfItems,
    selectCartLoaded,
    isAllDigitalProducts,
} from '@web-builder/store/cart/cart.selector';
import { CartActions } from '@web-builder/store/cart/cart.actions';
import type { ProductCard } from '@common/types/product-card.type';

@Injectable()
export class CartFacade {
    constructor(private readonly store: Store<CartFeatureState>) {}

    public readonly cartItems$: Observable<ProductCard[]> = this.store.select(selectCartItems);
    public readonly cartTotalPrice$: Observable<number> = this.store.select(selectCartTotalPrice);
    public readonly isAllDigitalProducts$: Observable<boolean> = this.store.select(isAllDigitalProducts);
    public readonly selectCartNumberOfItems$: Observable<number> = this.store.select(selectCartNumberOfItems);
    public readonly selectCartLoaded$: Observable<boolean> = this.store.select(selectCartLoaded);

    public addItemToCart(cartItem: ProductCard) {
        this.store.dispatch(CartActions.addItemToCart({ cartItem }));
    }

    public removeItemFromCart(cartItem: ProductCard) {
        this.store.dispatch(CartActions.removeItemFromCart({ cartItem }));
    }

    public getCartLocalStore(): void {
        this.store.dispatch(CartActions.cartLoading());
        this.store.dispatch(CartActions.getCartLocalStore());
    }

    public setQuantity(cartItem: ProductCard, selectedQuantity: number): void {
        this.store.dispatch(CartActions.setNumberOfItemInCart({ cartItem, selectedQuantity }));
    }
}
