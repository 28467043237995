import type { Observable } from 'rxjs';
import type { HttpDataResponse } from '@common/types/http-response.type';
import type { PaymentResponseType } from '@common/types/payment-response.type';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionHttpService {
    constructor(protected readonly http: HttpClient) {}

    public submitSubscription(url: any, payload: string): Observable<HttpDataResponse<any>> {
        return this.http.post<HttpDataResponse<PaymentResponseType>>(`${url}`, payload);
    }
}
