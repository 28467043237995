import type { ETime } from '@common/enums';
import { number } from 'joi';

export type RemainingTimeType = {
    [ETime.days]: string;
    [ETime.hours]: string;
    [ETime.minutes]: string;
    [ETime.seconds]: string;
};

export type RemainingTimeNumericType = {
    [ETime.days]: number;
    [ETime.hours]: number;
    [ETime.minutes]: number;
    [ETime.seconds]: number;
};

export type TimerData = {
    timerType?: ETimerType;
    untilDate: Date;
    remainingTime?: RemainingTimeNumericType;
    autorestart?: boolean;
    expiredText: string;
    columns: ETime[];
    customLabels: TimerCustomLabels;
};

export enum ETimerType {
    fixed = 'fixed',
    dynamic = 'dynamic',
}

export type TimerCustomLabels = {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
};
