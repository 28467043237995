import { type SelectedFileExtended } from '@common/types/file.type';

export type ProductCard = {
    name: string;
    description: string;
    shortDescription: string;
    price: number;
    oldPrice: number;
    images: SelectedFileExtended[];
    sections: Section[];
    vendorCode: string;
    quantity: number;
    selectedQuantity?: number;
    unit: ProductUnits;
    variants: Variant[];
    properties: Property[];
    id: string;
    cardUrl?: string;
    selectedProperties?: string[];
    type: ProductType;
    digitalProductId?: string;
};

export type Variant = {
    name: string;
    vendorCode: {
        value: string;
        isEditing: boolean;
    };
    isShow: boolean;
    quantity: {
        value: number;
        isEditing: boolean;
    };
    price: {
        value: number;
        isEditing: boolean;
    };
    oldPrice: {
        value: number;
        isEditing: boolean;
    };

    images: SelectedFileExtended[];
    properties: string[];
    id: string;
};

export type Property = {
    name: string;
    parameters: string[];
};

export type Section = {
    name: string;
    parameters: Params[];
};

export enum ProductUnits {
    pieces = 'pieces',
    service = 'service',
    meter = 'meter',
    kit = 'kit',
    kg = 'kg',
    l = 'l',
}

export type Params = {
    parameter: string;
    value: string;
};

export enum ProductType {
    digital = 'digital',
    physical = 'physical',
}
