/* eslint-disable @angular-eslint/no-input-rename */
import { CommonModule } from '@angular/common';
import {
    type AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    type OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule, BsDaterangepickerDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, listLocales, ruLocale, ukLocale } from 'ngx-bootstrap/chronos';

import { ELanguages } from '@common/enums';
import { formatRange } from '@sites/analytics/src/app/range-selector/range-format.util';
import { type FormattedRange, type RangeOption } from '@sites/analytics/src/app/types';

defineLocale(ELanguages.UA, ukLocale);
defineLocale(ELanguages.RU, ruLocale);
@Component({
    selector: 'sp-range-selector',
    templateUrl: './range-selector.component.html',
    styleUrls: ['./range-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, ButtonsModule, BsDatepickerModule, FormsModule, TranslateModule],
    encapsulation: ViewEncapsulation.None,
})
export class RangeSelectorComponent implements OnInit {
    @Input() config: BsDaterangepickerDirective['bsConfig'] = {};
    @Input('defaultRange') range!: RangeOption | Date[];
    @Input() locale: ELanguages = ELanguages.EN;
    @Input() timezone!: string;
    @Input() allTimeStartDate!: string;
    @Output() rangeChange = new EventEmitter<FormattedRange>();

    @ViewChild(BsDaterangepickerDirective) datepicker!: BsDaterangepickerDirective;

    localeService: BsLocaleService = inject(BsLocaleService);
    translate: TranslateService = inject(TranslateService);
    changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

    activeDateRange: Date[] = [];
    customRangeSelected = false;

    bsConfig: BsDaterangepickerDirective['bsConfig'] = {
        rangeInputFormat: 'YYYY-MM-DD',
        // rangeInputFormat: 'РРРР-ММ-ДД',
        dateInputFormat: 'YYYY-MM-DD',
        showWeekNumbers: false,
        containerClass: 'theme-blue',
        isAnimated: true,
        maxDate: new Date(),

        ...this.config,
    };

    ngOnInit(): void {
        this.localeService.use(listLocales().includes(this.locale) ? this.locale : ELanguages.EN);

        const formattedRange = formatRange(this.range, this.timezone, this.allTimeStartDate);
        this.activeDateRange = [formattedRange.from, formattedRange.to].map((date) => new Date(date));
        this.rangeChange.emit(formattedRange);

        this.changeDetectorRef.detectChanges();
    }

    datePickerChange = (range: Date[]): void => {
        if (this.customRangeSelected) {
            this.emitRange(range, 'range');
        }

        this.customRangeSelected = true;
    };

    emitRange = (range: RangeOption | Date[], type: 'fixed' | 'range'): void => {
        const formattedRange = formatRange(range, this.timezone, this.allTimeStartDate);

        if (type === 'fixed' && range !== this.range) {
            this.customRangeSelected = false;
            this.rangeChange.emit(formattedRange);
            this.activeDateRange = [formattedRange.from, formattedRange.to].map((date) => new Date(date));
        } else if (type === 'range' && range.length !== 0 && this.activeDateRange.length !== 0) {
            this.rangeChange.emit(formattedRange);
            // @ts-ignore
            this.range = null;
        }
    };
}
